@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,900;1,400;1,500&display=swap');

*{
    font-size: 12px;
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
    font-weight: 400;
    --primary-font: 'Playfair Display', serif;
    --secundary-font: 'Open Sans', sans-serif;
    color: #787878;
    font-family: 'Playfair Display', serif;
}
body{

    min-width: 320px;
    font-size: 16px;
    
    }
.App{
    background-color: #210b78;
    background-image: url('./img/backg.jpg');
    background-size: cover;
    background-position: center center;
    
}
.main{
    padding: 0.5em 1.5em 0.3em 1.5em;
    text-align: center;
    background-color: #fff;
    opacity: .9;
}

.title{
    font-size: 1.8em;
    font-weight: 800;
    text-align: left;
}
/*HEADER*/
header{
    font-family: var(--primary-font);
    width: 100%;
    background-color: transparent;
    color: #fff;
    padding: 15px;
    text-align: center;
}
#name{
    font-size: 3em;
    font-weight: 900;
    font-family: var(--primary-font);
}

.container-header{
    padding: 10px 20px;
}
.header-paragraph {
    padding: 5px;
    font-size: 1.4em;
}
.container__tech-logos{
    max-width: 190px;
    margin: 0 auto;
}
.tech-logos{
    width: 50px;
    padding: 5px;
}

.line{
    display: inline-block;
    width: 190px;
    height: 2px;
    background-color: #fff;
    opacity: .4;
    border-radius: 5em;
}
/*PROFILE*/

.profile{
    padding: 25px 0;
}
.profile-paragraph{
    text-align: left;
    margin: 10px 0;
    font-size: 1.3em;
}
button{
    margin: 10px;
    border-radius: 5px;
    width: 70px;
    font-family: var(--primary-font);

}

.buttons{
    margin: 5px 0;
    background-color: transparent;
    border: 2px solid;
    width: 100%;
    padding: 5px;
    font-size: .8em;
}
.buttons:hover{
    border: 2px solid blue;
    cursor: pointer;
}
/*WORK*/
.work{
    display: grid;
    grid-template-areas: "one" "two";
}
.card-project:nth-child(1){
    grid-area: one;
}
.card-project:nth-child(2){
    grid-area: two;
}
.card-project{
    max-width: 30em;
    min-width: 20em;
    margin: 30px auto;
}
.card-project__img{
    max-width: 100%;
    border-radius: 0.37em;
    box-shadow: 0px 0px 6px #777575;
}
.work__header-paragraph{
    font-size: 1.8em;
    text-align: left;
    font-weight: 800;
    font-style: italic;
}
.work__body-paragraph{
    font-size: 1.3em;
    text-align: left;
    padding-top: 1em;
}
.bullet{
    list-style-type: square;
}
.p-l12{
    padding-left: 12px;
}
.tech_bold{
    font-weight: 600;
    font-size: 1.1em;
}

/*CONTACT*/

form{
    display: grid;
    grid-template-areas: "one" "two" "three" "four";
    margin: 10px auto;
}
.form-item{
    border: none;
    background-color: #dbdbdb;
    font-size: .8em;
    border-radius: .2em;
    padding: 5px;
}
.form-item:nth-child(1){
    grid-area: one;
    margin: 15px 0;
    height: 2.2em;
}
.form-item:nth-child(2){
    margin-bottom: 15px;
    height: 2.2em;
    grid-area: two;
}
.form-item:nth-child(3){
    grid-area: three;
}
.form-item:nth-child(4){
    grid-area: four;
}
.contactIcon{
    width: 20px;
    margin-top: 20px;
}

/*FOOTER*/

footer{
    background-color: transparent;
    padding: 45px;
    text-align: center;
}
.footer__contact-icon{
    margin: 10px;
    width: 55px;
}
footer > p{
    font-size: 1.5em;
}

@media only screen and (min-width:480px){
    .work{
        display: grid;
        gap: 1.2em;
        grid-template-areas: "one two";
    }
    .card-project{
        min-width: revert;
    }
    
    .buttons{
        display: block;
        width: 40%;
    }
    
}
@media only screen and (min-width:980px){
    .App{
        display: flex;
        position: relative;
    }
    .main{
        min-height: 1000px;
    }

    header{
        width: 38em;
        position: relative;
    }
    .header-paragraph{
        font-size: 1.4em;
    }
    #name{
        margin-bottom: 15px;
    }
    .container-header{
        position: fixed;
        left:1.5em;
        top: 5em;
    }
    .profile-paragraph{
        font-size: 1.2em;
    }
    .work{
        gap: 2em;
    }
    .work__header-paragraph{
        font-size: 1.7em;
    }
    .work__body-paragraph{
        font-size: 1.2em;
    }
    .card-project{
        min-width: revert;
        max-width: revert;
        
    }

    .card-project__img:hover{
        opacity: .7;
        cursor: pointer;
    }
    .container__tech-logos{
        max-width: 150px;
    }
    .tech-logos{
        width: 50px;
    }
    /*.tech-logos__inprogress{
        border-radius: 50%;
        background-color: white;
        padding: 1px;
    }*/
    footer{
        position: fixed;
        left: .8em;
        top: 32em;
        background-color: transparent;
        margin-top: 25px;
    }
    footer >p {
        font-size: 1.2em;
    }
    .footer__contact-icon{
        width: 55px;
    }
    .footer__contact-icon:hover{
        transform: scale(1.2);
        transition-duration: .7s;
        border-radius: 1em;
        background-color: rgb(128,0,128);
        opacity: .5;
    }
    .main{
        width: 100%;
    }
    .buttons{
        width: 15%;
        margin-top: 2em;
    }


}

@media only screen and (min-width:1400px){
    .profile-paragraph{
       margin: 10px 550px 10px 0;
       font-size: 1.4em;
    }
}
